import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector, } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SweetPagination from "sweetpagination";
import {  faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import Devis from './Devis';
import DetailCommande from './DetailCommande';
import { network } from "../constants";
import  { agentsFetch } from "../slices/AgentSlice";
import { agencesFetch, } from "../slices/agenceSlice";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import { StatusOptions } from '../data.js'
import axiosInstance from './api';
import { Hidden } from '@mui/material';


const Commande = () => {
  const dispatch = useDispatch()
 


 

  useEffect(() => {
   
      statusFetch()
      commandesFetch()
      FetchClassementAgence()
      FetchClassementAgent()
  

  }, [])



  
  var today = new Date();
  var dd = ("0" + (today.getDate())).slice(-2);
  var mm = ("0" + (today.getMonth() + 1)).slice(-2);
  const [error, setError] = useState("");
  const yyyy = today.getFullYear();
  const DateDebut = `${yyyy}-01-01`
  const DateFin = `${yyyy}-${mm}-${dd}`;
  const [Toggle, setToggle] = useState(true)
  
  const [Datedebut, setDatedebut] = useState(DateDebut)
  const [Datefin, setDatefin] = useState(DateFin)
  const [cmd, setcmd] = useState([])
  const [Allvoyage, setAllvoyage] = useState([])
  const [Allcmd, setAllcmd] = useState([])
  const [Count, setCount] = useState([])
  const [statut, setstatut] = useState([])
  const [show, setShow] = useState(false);
  const [showcmd, setShowcmd] = useState(false);
  const [NbrTotal, setNbrTotal] = useState(0)
  const [NbrValid, setNbrValid] = useState(0)
  const [NbrConfirm, setNbrConfirm] = useState(0)
  const [NbrAttente, setNbrAttente] = useState(0)
  
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShowcmd(false);
  const [isloading, setIsloading] = useState(false);
  
  const [AgenceClassement, setAgenceClassement] = useState([]);
  const [AgentClassement, setAgentClassement] = useState([]);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const FetchClassementAgence = async() => {
    await axiosInstance.get(`/Classement/Agence`)
     


      // .then((response) => response.json())
      .then((res) => {

        setAgenceClassement(res.data)
      })
  }
  const FetchClassementAgent = async() => {
    await axiosInstance.get(`/Classement/Agent`)
   

      // .then((response) => response.json())
      .then((res) => {

        setAgentClassement(res.data)
      })
  }

  const statusFetch = async() => {
    // user = JSON.parse(localStorage.getItem('user'))
    await axiosInstance.get(`/Classement/DR`)

     
      .then((res) => {
        
        setCount(res.data)
      })
  }


  useEffect(() => {
    dispatch(agencesFetch())
    dispatch(agentsFetch())
  }, [dispatch])


  const commandesFetch = async() => {
    setIsloading(true);
    //  
 
    await axiosInstance.get(`/commandes`)
    
      
      .then((res) => {
        
        setAllvoyage(res.data)
        setAllcmd(res.data)
        setNbrTotal(res.data.length)
        setNbrAttente((res.data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
        setNbrValid((res.data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)
        setNbrConfirm((res.data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 2)).length)
        setIsloading(false);
        // setDr(AllDR.filter(x=>x.label ==agent.DR))
      })
      .catch((error) => {
        setError(error.message);
        console.log("error", error);
        setIsloading(false);
      });
  }

  const toggle = () => {
    setToggle(!Toggle)
  }

  const rechercher = () => {
    if (statut.length > 0) {
      const data = Allcmd.filter(row => row.MDate.substring(0, 10) >= Datedebut && row.MDate.substring(0, 10) <= Datefin && row.status === statut[0]['id'])
      setAllvoyage(data)
      setNbrTotal(data.length)
      setNbrAttente((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
      setNbrValid((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)
    } else {
      const data = Allcmd.filter(row => row.MDate.substring(0, 10) >= Datedebut && row.MDate.substring(0, 10) <= Datefin)
      setNbrAttente((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
      setNbrValid((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)
      setNbrTotal(data.length)
      setAllvoyage(data)
    }



  }

  const Delete = (id) => {

    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'vous êtes sûr de la suppression ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(`${network.serverip}/commandes/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",

            }
          })
          MySwal.fire(
            'Suppression avec succès  !',
            '',
            'success'
          ).then((res) => {
            if (res.isConfirmed) {

              window.location.reload()
            }
          })

        }
      })
  }


  const FormatDate = (value) => {

    var date = new Date(value);
    return `${('0' + date.getUTCDate()).slice(-2)}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${date.getUTCFullYear()}`;
  }


  const expandRow = {
    renderer: (row) => (
      <div>
        <h6>{row.LibArt}</h6>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true
  };
  const expandRow2 = {
    renderer: (row) => (
      <div>
        <h6>{row.Agence}</h6>
        <h6>{row.DR}</h6>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true
  };



  const handleOnExpand = (row, isExpand) => {

    console.log(isExpand ? 'Expanding' : 'Collapsing')





  }

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: 'Nf',
      text: 'Numéro',
      // footer: 'Total :',
      align: 'center',
      // filter: textFilter(),
      // style: { 'width': '120px' }
    },
    {
      dataField: 'MDate',
      text: 'Date',
      formatter: (cell) => (cell && FormatDate(cell))
    },

    {
      dataField: 'LibTiers',
      text: 'Client',
      style: { 'width': '200px', },
      // filter: textFilter(),
      // style: {
      //   'width': '350px',
      //   //  'color' :'red'                  
      // }

    },
    {
      dataField: 'Remarq1',
      text: 'Téléphone',
      style: { 'width': '150px' }
    },
    {
      dataField: 'Matricule',
      text: 'Matricule',
      // filter: textFilter(),
      // style: { 'width': '150px' }
    },
    {
      dataField: 'agent',
      text: 'Agent',
      // filter: textFilter(),
      // style: { 'width': '150px' }
    },
    {
      dataField: 'CodeAgence',
      text: 'Code',
      // filter: textFilter(),
     
    },

    {
      dataField: 'Agence',
      text: 'Agence',
      style: { 'width': '250px' }
      // editor: {
      //   type: Type.SELECT,
      //   getOptions: (setOptions, { row, column }) => {
      //        setOptions(Allagence)  
      //   }
      // }
      // filter: textFilter(),
      // style: { 'width': '150px' }
    }
    ,
    {
      dataField: 'DR',
      text: 'DR',
      // filter: textFilter(),
      
    },

    {
      dataField: 'ResponsableDR',
      text: 'Responsable DR',
      // filter: textFilter(),
      style: { 'width': '250px' }
    }
    ,
    {
      dataField: 'DateValidation',
      text: 'Date validation',
      formatter: (cell) => (cell && FormatDate(cell)),
      // filter: textFilter(),
      // style: { 'width': '200px' }
    },

    {
      dataField: 'status',
      text: 'Statut',
      style: { 'width': '120px' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.status == 0 ?
              (

                <div className='px-2 text-white text-center bg-black rounded-1 text-capitalize' >en attente</div>
 
              ) : row.status == 1 ? (

                <div className='px-2 text-black text-center bg-success rounded-1 text-capitalize'>validée</div>
              ) : row.status == 2 ? (
                <div className='px-2 text-black text-center bg-warning rounded-1 text-capitalize' >confirmée</div>
              ) : row.status == 3 ? (
                <div className='px-2 text-black text-center bg-info rounded-1 text-capitalize' >Livrée</div>
              ) : (
                <div className='px-2 text-black text-center bg-danger rounded-1 text-capitalize' style={{width:"auto"}}>Annulée</div>
              )


            }
          </div>

        )
      }

    },
    {
      dataField: 'nbr',
      text: 'Expirée',
      style: { 'width': '100px', },
      formatter: (cell, row) => {
        return (
          <div>
            {row.status == 0 &&
              <span>{row.nbr > 0 ? row.nbr : "Expiré"} jours </span>
            }
          </div>
        )
      }
    },

    {
      dataField: 'act',
      text: 'Action',
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div className='d-flex justify-content-around'>
            <button title='Consulter' className='btn btn-secondary me-1' size="sm"
              onClick={() => { setShow(true); setcmd(row) }}
            // onClick={() =>{history.push({
            //   pathname: '/Devis',
            //   search: `?Nf=${row.Nf}`,
            //   state: `${row.Nf}`
            // })}}
            >
              <i className='bi bi-printer'></i>
            </button>
            <button title='Editer' className='btn btn-primary me-1' size="sm"
              onClick={() => { setShowcmd(true); setcmd(row) }}>
              <i className='bi bi-pencil'></i>
            </button>

            <button title='Supprimer' className='btn btn-danger' size="sm"
              // disabled={row.status==0?false:true}  
              onClick={() => Delete(row.Guid)}
            >
              <i className='bi bi-trash'></i>
            </button>



          </div>

        )
      },

    }






  ];
  const columns3 = [
    // {
    //   dataField: 'Row',
    //   text: 'N°',
    //   align: 'left',
     
    // },
    {
      dataField: 'Matricule',
      text: 'Matricule',
   
    }
    ,
    {
      dataField: 'agent',
      text: 'Agent',
   
    }
    ,
    {
      dataField: 'Agence',
      text: 'Agence',
      
   
    },
    {
      dataField: 'DR',
      text: 'DR',
   
    }
    ,
    {
      dataField: 'total',
      text: 'Total',
      // footer: 'Total :',
      align: 'center',
      // filter: textFilter(),
      // style: { 'width': '120px' }
    },
  ]
  const columns2 = [
    // {
    //   dataField: 'Row',
    //   text: 'N°',
    //   align: 'left',
    //   // footer: 'Total :',

    //   // filter: textFilter(),
    //   // style: { 'width': '120px' }
    // },
    {
      dataField: 'label',
      text: 'DR',
      // editor: {
      //   type: Type.SELECT,
      //   getOptions: (setOptions, { row, column }) => {
      //        setOptions(Allagence)  
      //   }
      // }
      // filter: textFilter(),
      // style: { 'width': '150px' }
    }
    ,

    {
      dataField: 'total',
      text: 'Total',
      // footer: 'Total :',
      align: 'center',
      // filter: textFilter(),
      // style: { 'width': '120px' }
    },
  ]
 

  const columns4 = [
   
    
    {
      dataField: 'Agence',
      text: 'Agence',
   
    },
    {
      dataField: 'DR',
      text: 'DR',
   
    }
    ,
    {
      dataField: 'total',
      text: 'Total',
      // footer: 'Total :',
      align: 'center',
      // filter: textFilter(),
      // style: { 'width': '120px' }
    },
  ]
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Allvoyage.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(Allvoyage.length / itemsPerPage);

  // Array of page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    


    <main className='main-container'>
        
       
           <div>
          {Allvoyage && Allvoyage.length === 0 && isloading === false &&
            <div className="cart-empty" >
              <div className=' alert alert-danger' role='alert'>Liste des commandes est vide !</div>


            </div>
          }




          <div >

            <div className='row  p-3' style={{ backgroundColor: "#F1F1F1" ,margin:1}}>
              {/* <div className='col-md-3'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded'>
                  <div>
                    <h3 className='fs-2'>23</h3>
                    <p className='fs-5'>Nbr Commandes</p>
                  </div>
                </div>
              </div> */}
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-receipt fs-2'></i> */}
                  <div>
                    <h5 className='fs-2'>{NbrTotal}</h5>
                    <p className='fs-5'>Nombre de commandes</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-hourglass-bottom fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrAttente}</h3>
                    <p className='fs-5'>Commandes en attentes</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-circle fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrValid}</h3>
                    <p className='fs-5'>Commandes validées</p>
                  </div>
                </div>

              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-all  fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrConfirm}</h3>
                    <p className='fs-5'>Commandes Confirmées</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-  fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrConfirm}</h3>
                    <p className='fs-5'>Commandes Annulées</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-circle fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrValid}</h3>
                    <p className='fs-5'>Commandes Livrées</p>
                  </div>
                </div>

              </div>
              <div className='mx-3' style={{ backgroundColor: "#F1F1F1" }} >

                <Row className="mx-2 my-3 justify-content-center " >

                  <Col md={2} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Date Début  </Form.Label>
                      <Form.Control type="date" placeholder="Date Debut " defaultValue={DateDebut} onChange={(e) => setDatedebut(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Date Fin </Form.Label>
                      <Form.Control type="date" placeholder="Date Fin " defaultValue={DateFin} onChange={(e) => setDatefin(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="mb-3 ">
                    <Form.Group id="phone">
                      <Form.Label>Statut  </Form.Label>
                      <Typeahead
                        id="my-typeahead-id"
                        labelKey="label"
                        // required
                        // ref={RefDR}
                        defaultValue={statut}
                        onChange={(item) => { setstatut(item) }}
                        options={StatusOptions}
                        placeholder="----"
                        selected={statut}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-4 p-2">
                    <Button variant='secondary'
                      onClick={rechercher} ><i className='bi bi-search'></i></Button>
                  </Col>



                </Row>

              </div>
            </div>
            {window.innerWidth < 1000 ?(
              <div>
                   <div className='grid grid-cols-1'>
 
 { currentPageData.map((row)=>(
    <div className='bg-white shadow-sm border-1'>
      <div className='d-flex flex-auto items-center justify-content-start'>
       <div className='mx-2'>{row.Matricule}</div>
       <div>{row.agent}</div>
       </div>
       <div className='d-flex flex-auto items-center justify-content-start'>
         <div className='mx-2'>{row.CodeAgence}</div>
       <div>{row.Agence}</div>
       </div>
     <div className='d-flex flex-auto items-center justify-content-between'>
     <div className='text-primary'>#{row.Nf}</div>
   <div className='text-secondary'>{FormatDate(row.MDate)}</div>
   <div>
         {row.status == 0 ?
           (

             <span className='px-2 text-white bg-black rounded-1 text-capitalize' >en attente</span>

           ) : row.status == 1 ? (

             <span className='px-2 text-white bg-success rounded-1 text-capitalize'>validée</span>
           ) : row.status == 2 ? (
             <span className='px-2 text-black bg-warning rounded-1 text-capitalize' >confirmée</span>
           ) : row.status == 3 ? (
            <span className='px-2 text-black bg-info rounded-1 text-capitalize' >Livrée</span>
           ) : (
            <span className='px-2 text-black bg-danger rounded-1 text-capitalize' >Annulée</span>
           )


         }
       </div>
      
   </div>
   <div className='fw-bold'>{row.LibTiers}</div>
    <div>{row.LibArt}</div>
    <div className='d-flex justify-content-center py-2'>
         <button title='Consulter' className='btn btn-secondary me-1' size="sm"
           onClick={() => { setShow(true); setcmd(row) }}
   
         >
           <i className='bi bi-printer'></i>
         </button>
         <button title='Editer' className='btn btn-primary me-1' size="sm"
           onClick={() => { setShowcmd(true); setcmd(row) }}>
           <i className='bi bi-pencil'></i>
         </button>

         <button title='Supprimer' className='btn btn-danger' size="sm"
           // disabled={row.status==0?false:true}  
           onClick={() => Delete(row.Guid)}
         >
           <i className='bi bi-trash'></i>
         </button>



       </div>
   </div>
 ))}

</div>
<div className="pagination my-2">
<SweetPagination
                
                currentPageData={setCurrentPageData}
                dataPerPage={10}
                getData={Allvoyage}
                navigation={true}
                
               
                
              />
     {/* <button onClick={() => paginate(1)} disabled={currentPage === 1}>First</button>
     <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
     {pageNumbers.map(number => (
       <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>
         {number}
       </button>
     ))}
     <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(Allvoyage.length / itemsPerPage)}>Next</button>
     <button onClick={() => paginate(Math.ceil(Allvoyage.length / itemsPerPage))} disabled={currentPage === Math.ceil(Allvoyage.length / itemsPerPage)}>Last</button> */}
   </div>
              </div>
            ):(
              <div>
                   {Allvoyage.length > 0 && 
              <div>
                <ToolkitProvider
                  keyField="CodPer"
                  data={Allvoyage}
                  columns={columns}
                  search
                     
                  exportCSV={{
                    onlyExportFiltered: true, exportAll: false,
                    fileName: "Liste_Commandes.csv",
                    blobType: 'text/plain;charset=utf-8 ',
                    noAutoBOM: false,
                    separator: ";"
                  }}
                >
                  {
                    props => (<div className='my-2 mb-4 '>
                      
                      <Row>
                        <Col md={2}>
                          <SearchBar {...props.searchProps} placeholder="Rechercher ..." className='border-primary mx-3 mb-3 w-500' />
                        </Col>

                        <Col md={2}>
                          <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter</ExportCSVButton>
                        </Col>
                      </Row>
                      <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                        <Col>
                          <BootstrapTable
                            keyField='Nf'
                            striped
                            hover
                            condensed
                          
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            {...props.baseProps}
                            expandRow={expandRow}
                            onExpand={handleOnExpand}
                          />
                        </Col>
                      </Row>
                    </div>
                    )
                  }

                </ToolkitProvider>
              </div>
            }
              </div>
            )
}


         
         



            <div className='row p-2' style={{ backgroundColor: "#F1F1F1" }}>

              {AgenceClassement.length > 0 &&
                <div className='col-md-auto mx-2 my-2 p-3 bg-white shadow-lg  d-flex justify-content-around align-items-center rounded-2'>
                  <ToolkitProvider
                    keyField="CodPer"
                    data={AgenceClassement}
                    columns={columns4}
                    search
                    exportCSV={{
                      onlyExportFiltered: true, exportAll: false,
                      fileName: "Liste_Commandes.csv",
                      blobType: 'text/plain;charset=utf-8 ',
                      noAutoBOM: false,
                      separator: ";"
                    }}
                  >
                    {
                      props => (<div>
                        <h4 className='text-center text-decoration-underline mb-5'>Etat de vente par Agence</h4>
                        <Row>
                          <Col md={8}>
                            <SearchBar {...props.searchProps} placeholder="Rechercher ..." className='border-primary mx-3 mb-3 w-500' />
                          </Col>

                          <Col md={4}>
                            <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} /> Exporter</ExportCSVButton>
                          </Col>
                        </Row>
                        <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                          <Col>
                            <BootstrapTable

                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              {...props.baseProps}
                              expandRow={expandRow}
                              onExpand={handleOnExpand}
                            />
                          </Col>
                        </Row>
                      </div>
                      )
                    }

                  </ToolkitProvider>
                </div>
              }


{AgenceClassement.length > 0 &&
            <div   className='col-md-auto mx-2 my-2 p-3 bg-white shadow-lg  d-flex justify-content-around align-items-center rounded-2'>
                  <ToolkitProvider
                    keyField="CodPer"
                    data={AgentClassement}
                    columns={columns3}
                    search
                    exportCSV={{
                      onlyExportFiltered: true, exportAll: false,
                      fileName: "Liste_Commandes.csv",
                      blobType: 'text/plain;charset=utf-8 ',
                      noAutoBOM: false,
                      separator: ";"
                    }}
                  >
                    {
                      props => (<div>
                        <h4 className='text-center text-decoration-underline mb-5'>Etat de vente par Agent</h4>
                        <Row>
                          <Col md={8}>
                            <SearchBar {...props.searchProps} placeholder="Rechercher ..." className='border-primary mx-3 mb-3 w-500' />
                          </Col>

                          <Col md={4}>
                            <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} /> Exporter</ExportCSVButton>
                          </Col>
                        </Row>
                        <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                          <Col>
                            <BootstrapTable

                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              {...props.baseProps}
                              expandRow={expandRow2}
                              onExpand={handleOnExpand}
                            />
                          </Col>
                        </Row>
                      </div>
                      )
                    }

                  </ToolkitProvider>
                </div>
              }
             


              {Count.length > 0 &&
                 <div   className='col-md-auto mx-2 my-2 bg-white shadow-lg  d-flex justify-content-around align-items-center rounded-2'>
                  <ToolkitProvider
                    keyField="CodPer"
                    data={Count}
                    columns={columns2}
                    search
                    exportCSV={{
                      onlyExportFiltered: true, exportAll: false,
                      fileName: "Liste_Commandes.csv",
                      blobType: 'text/plain;charset=utf-8 ',
                      noAutoBOM: false,
                      separator: ";"
                    }}
                  >
                    {
                      props => (<div className='mx-2'>
                       <h4 className='text-center text-decoration-underline mb-5'>Etat de vente par DR </h4>
                        <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                          <Col>
                            <BootstrapTable
                              keyField='Nf'
                              striped
                              hover
                              condensed
                              // pagination={paginationFactory()}
                              // filter={filterFactory()}
                              {...props.baseProps}
                            // expandRow={expandRow}
                            // onExpand={handleOnExpand}
                            />
                          </Col>
                        </Row>
                      </div>
                      )
                    }

                  </ToolkitProvider>
                </div>
              }

            </div>


            <Modal
              className='modal'
              dialogClassName='modal-container'
              aria-labelledby="contained-modal-title-vcenter"
              show={show}
              onHide={handleClose}
              size='lg'
            >
              <Modal.Header style={{ backgroundColor: '#DCDCDC' }}>
                <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'white' }}>

                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Devis cmd={cmd} />


              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

              </Modal.Footer>
            </Modal>


            <Modal
              className='modal'
              dialogClassName='modal-dialog modal-lg'
              // aria-labelledby="contained-modal-title-vcenter"
              show={showcmd}
              // onHide={handleClose2}
              size='xl'
            >
              <Modal.Header style={{ backgroundColor: '#DCDCDC' }}>
                <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'black' }}>
                  Bon de commande N° : {cmd.Nf}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DetailCommande cmd={cmd}
                  onPress={handleClose2}
                />
                {/* <AddEmployer Emp={Emp} /> */}

              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

              </Modal.Footer>
            </Modal>
          </div>


           </div>
    

  </main>  
  );
}


export default Commande;



