import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Modal, Form,Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector, } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import Devis from './Devis';
import DetailCommande from './DetailCommande';

import { agentsFetch } from "../slices/AgentSlice";
import { agencesFetch, } from "../slices/agenceSlice";
import { StatusOptions } from '../data.js'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import axiosInstance from './api';
import Header from './Header.jsx';
import { Link } from "react-router-dom";
const ListCommande = () => {
 

  const dispatch = useDispatch();

  const { agences, status } = useSelector((state) => state.agences);
  var today = new Date();
  var dd = ("0" + (today.getDate())).slice(-2);
  var mm = ("0" + (today.getMonth() + 1)).slice(-2);

  const yyyy = today.getFullYear();
  const DateDebut = `${yyyy}-01-01`
  const DateFin = `${yyyy}-${mm}-${dd}`;

  const [error, setError] = useState("");
  const [statut, setstatut] = useState([])
  const [Datedebut, setDatedebut] = useState(DateDebut)
  const [Datefin, setDatefin] = useState(DateFin)
  const [cmd, setcmd] = useState([])
  const [Allvoyage, setAllvoyage] = useState([])
  const [Allcmd, setAllcmd] = useState([])
  
  const [show, setShow] = useState(false);
  const [showcmd, setShowcmd] = useState(false);
  const [NbrTotal, setNbrTotal] = useState(0)
  const [NbrValid, setNbrValid] = useState(0)
  const [NbrConfirm, setNbrConfirm] = useState(0)
  const [NbrAttente, setNbrAttente] = useState(0)
 
  const [isloading, setIsloading] = useState(false);
  const [ClassAgent,setClassAgent]=useState([])
  const [ClassAgence,setClassAgence]=useState([])
  const [ClassDR,setClassDR]=useState([])
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShowcmd(false);
  const id = localStorage.getItem('session');
  useEffect(() => {
    if(id)
      {
    setIsloading(true);
    axiosInstance.get(`/Classement/Tiers/${id}`)
    .then((res) => {
      // console.log(res.data)
      setClassAgent(res.data)
    })
      }
    },[])
    useEffect(() => {
      if(id)
        {
      setIsloading(true);
      axiosInstance.get(`/Classement/Agence/${id}`)
      .then((res) => {
        // console.log(res.data)
        setClassAgence(res.data)
      })
        }
      },[])
    useEffect(() => {
      if(id)
        {
      setIsloading(true);
      axiosInstance.get(`/Classement/DR/${id}`)
      .then((res) => {
        // console.log(res.data)
        setClassDR(res.data)
      })
        }
      },[])
  useEffect(() => {
    if(id)
      {
    setIsloading(true);
    axiosInstance.get(`/commandes/Tiers/${id}`)
    
    
      .then((res) => {
        setIsloading(false);
        setAllvoyage(res.data)
        setAllcmd(res.data)
        setNbrTotal(res.data.length)
        setNbrAttente((res.data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
        setNbrValid((res.data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)

      })
      .catch((error) => {
        setError(error.message);
        console.log("error", error);
        setIsloading(false);
      });
    }
  }, [])
 
  useEffect(() => {
    dispatch(agencesFetch())
    dispatch(agentsFetch())
  }, [dispatch])

  const rechercher = () => {
    if (statut.length > 0) {
      const data = Allcmd.filter(row => row.MDate.substring(0, 10) >= Datedebut && row.MDate.substring(0, 10) <= Datefin && row.status === statut[0]['id'])
      setAllvoyage(data)
      setNbrTotal(data.length)
      setNbrAttente((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
      setNbrValid((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)
    } else {
      const data = Allcmd.filter(row => row.MDate.substring(0, 10) >= Datedebut && row.MDate.substring(0, 10) <= Datefin)
      setNbrAttente((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 0)).length)
      setNbrValid((data.filter(x => x.MDate.substring(0, 10) >= Datedebut && x.MDate.substring(0, 10) <= Datefin && x.status === 1)).length)
      setNbrTotal(data.length)
      setAllvoyage(data)
    }



  }

  const Delete = (id) => {

    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous annuler votre commande ?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.put(`/commandes/annuler/${id}`)
        
          .then((res) => {
            MySwal.fire(
              'annulé avec succès   !',
              'annulation .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {

                window.location.reload()
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
       
      }
    })
  }



  const FormatDate = (value) => {

    var date = new Date(value);
    return `${('0' + date.getUTCDate()).slice(-2)}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${date.getUTCFullYear()}`;
  }

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const expandRow = {
    renderer: (row) => (
      <div>
        <h6>{row.LibArt}--- N°:{row.NumChass}</h6>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true
  };
  const columns = [
    {
      dataField: 'Nf',
      text: 'Numéro',
      // footer: 'Total :',
      align: 'center',
      // filter: textFilter(),
      style: { 'width': '120px' }
    },
    {
      dataField: 'MDate',
      text: 'Date création',
      formatter: (cell) => (cell && FormatDate(cell))
    },

    {
      dataField: 'LibTiers',
      text: 'Client',
      style: { 'width': '200px', },
      // filter: textFilter(),
      // style: {
      //   'width': '350px',
      //   //  'color' :'red'                  
      // }

    },
    {
      dataField: 'Remarq1',
      text: 'Téléphone',
      style: { 'width': '150px' }
    },
    {
      dataField: 'Matricule',
      text: 'Matricule',
      // filter: textFilter(),
      // style: { 'width': '150px' }
    },
    {
      dataField: 'agent',
      text: 'Agent',
      // filter: textFilter(),
      // style: { 'width': '150px' }
    },
    {
      dataField: 'CodeAgence',
      text: 'Code agence',
      // filter: textFilter(),
      style: { 'width': '100px' }
    },

    {
      dataField: 'Agence',
      text: 'Agence',
      // editor: {
      //   type: Type.SELECT,
      //   getOptions: (setOptions, { row, column }) => {
      //        setOptions(Allagence)  
      //   }
      // }
      // filter: textFilter(),
      // style: { 'width': '150px' }
    }
    ,
    {
      dataField: 'DR',
      text: 'DR',
      // filter: textFilter(),
      style: { 'width': '120px' }
    },

    {
      dataField: 'ResponsableDR',
      text: 'Responsable DR',
      // filter: textFilter(),
      style: { 'width': '200px' }
    }
    ,
    {
      dataField: 'DateValidation',
      text: 'Date validation',
      formatter: (cell) => (cell && FormatDate(cell)),
      // filter: textFilter(),
      // style: { 'width': '200px' }
    },

    {
      dataField: 'status',
      text: 'Statut',
      style: { 'width': '150px' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.status == 0 ?
              (

                <div className='px-2 text-white text-center bg-black rounded-1 text-capitalize' >en attente</div>
 
              ) : row.status == 1 ? (

                <div className='px-2 text-black text-center bg-success rounded-1 text-capitalize'>validée</div>
              ) : row.status == 2 ? (
                <div className='px-2 text-black text-center bg-warning rounded-1 text-capitalize' >confirmée</div>
              ) : row.status == 3 ? (
                <div className='px-2 text-black text-center bg-info rounded-1 text-capitalize' >Livrée</div>
              ) : (
                <div className='px-2 text-black text-center bg-danger rounded-1 text-capitalize' style={{width:"auto"}}>Annulée</div>
              )


            }
          </div>

        )
      }

    },
    {
      dataField: 'nbr',
      text: 'Expirée',
      style: { 'width': '100px', },
      formatter: (cell, row) => {
        return (
          <div>
            {row.status === 0 &&
              <span>{row.nbr > 0 ? row.nbr : "Expiré"} jours </span>
            }
          </div>
        )
      }
    },

    {
      dataField: 'act',
      text: 'Action',
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div className='d-flex justify-content-around'>
            <button title='Consulter' className='btn btn-secondary me-2' size="sm"
              onClick={() => { setShow(true); setcmd(row) }}
            // onClick={() =>{history.push({
            //   pathname: '/Devis',
            //   search: `?Nf=${row.Nf}`,
            //   state: `${row.Nf}`
            // })}}
            >
              <i className='bi bi-printer'></i>
            </button>
            <button title='Editer' className='btn btn-primary me-2' size="sm"
              onClick={() => { setShowcmd(true); setcmd(row) }}>
              <i className='bi bi-pencil'></i>
            </button>

            <button title='Supprimer' className='btn btn-danger' size="sm"
              // disabled={row.status==0?false:true}  
              onClick={() => Delete (row.Nf)}
            >
              <i className='bi bi-trash'></i>
            </button>


            {/* <Button variant="outline-info" size="sm" 
 
    }>
      
         <FontAwesomeIcon icon={faEye} className="me-2" /> 
          Aperçu </Button>
          <Button className='mx-1' variant="outline-success" size="sm" 
       >
           <FontAwesomeIcon color='outline-danger' icon={faPencil} className="me-2" />  
          Editer </Button>
          <Button className='mx-1' variant="outline-danger" size="sm" 
       >
          {/* <FontAwesomeIcon color='outline-danger' icon={faCheck} className="me-2" />  */}

          </div>

        )
      },

    }






  ];





  return (
    <>
  {id &&  <div>
      <div className="row">
        <Header />
        {Allvoyage && Allvoyage.length === 0 && isloading===false &&
          <div className="cart-empty" >
            <div className=' alert alert-danger' role='alert'>Liste des commandes est vide !</div>


          </div>
        }

        <div className="col">






          <div>
          <div className="start-shopping">
               <Link to="/">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="20"
                   height="20"
                   fill="currentColor"
                   className="bi bi-arrow-left"
                   viewBox="0 0 16 16"
                 >
                   <path
                     fillRule="evenodd"
                     d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                   />
                 </svg>
                 <span>Retour</span>
               </Link>
             </div>

            <div className='row g-3 my-2 mx-2' >
            <div className='col-md-4 my-2'>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Agent</th>
          <th>Total</th>
          
        </tr>
      </thead>
      <tbody>
        {ClassAgent.map((item,i)=>(
           <tr key={i}>
          <td>{item.Row}</td>
          <td>{item.agent}</td>
          <td>{item.Total}</td>
        
        </tr>
        )
        
      )

        }
       
      </tbody>
    </Table>
            </div>
            <div className='col-md-4 my-2'>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>DR</th>
          <th>Total</th>
          
        </tr>
      </thead>
      <tbody>
        {ClassDR.map((item,i)=>(
           <tr key={i}>
          <td>{item.Row}</td>
          <td>{item.label}</td>
          <td>{item.total}</td>
        
        </tr>
        )
        
      )

        }
       
      </tbody>
    </Table>
            </div>
            <div className='col-md-4 my-2'>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Agence</th>
          <th>Total</th>
          
        </tr>
      </thead>
      <tbody>
        {ClassAgence.map((item,i)=>(
           <tr key={i}>
          <td>{item.Row}</td>
          <td>{item.Agence}</td>
          <td>{item.Total}</td>
        
        </tr>
        )
        
      )

        }
       
      </tbody>
    </Table>
            </div>
            </div>
            <div className='row g-3 my-2 mx-2' style={{ backgroundColor: "#F1F1F1" }}>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-receipt fs-2'></i> */}
                  <div>
                    <h5 className='fs-2'>{NbrTotal}</h5>
                    <p className='fs-5'>Nombre de commandes</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-hourglass-bottom fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrAttente}</h3>
                    <p className='fs-5'>Commandes en attentes</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-circle fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrValid}</h3>
                    <p className='fs-5'>Commandes validées</p>
                  </div>
                </div>

              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-all  fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrConfirm}</h3>
                    <p className='fs-5'>Commandes Confirmées</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-  fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrConfirm}</h3>
                    <p className='fs-5'>Commandes Annulées</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2 my-2'>
                <div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded-2'>
                  {/* <i className='bi bi-check-circle fs-2'></i> */}
                  <div>
                    <h3 className='fs-2'>{NbrValid}</h3>
                    <p className='fs-5'>Commandes Livrées</p>
                  </div>
                </div>
            </div>
           
            <div className='mx-3' style={{ backgroundColor: "#F1F1F1" }} >

<Row className="mx-2 my-3 justify-content-center " >

  <Col md={2} className="mb-3">
    <Form.Group id="phone">
      <Form.Label>Date Début  </Form.Label>
      <Form.Control type="date" placeholder="Date Debut " defaultValue={DateDebut} onChange={(e) => setDatedebut(e.target.value)} />
    </Form.Group>
  </Col>
  <Col md={2} className="mb-3">
    <Form.Group id="phone">
      <Form.Label>Date Fin </Form.Label>
      <Form.Control type="date" placeholder="Date Fin " defaultValue={DateFin} onChange={(e) => setDatefin(e.target.value)} />
    </Form.Group>
  </Col>
  <Col md={2} className="mb-3 ">
    <Form.Group id="phone">
      <Form.Label>Statut  </Form.Label>
      <Typeahead
        id="my-typeahead-id"
        labelKey="label"
        // required
        // ref={RefDR}
        defaultValue={statut}
        onChange={(item) => { setstatut(item) }}
        options={StatusOptions}
        placeholder="----"
        selected={statut}
      />
    </Form.Group>
  </Col>
  <Col className="mt-4 p-2">
    <Button variant='secondary'
      onClick={rechercher} ><i className='bi bi-search'></i></Button>
  </Col>



</Row>

</div>
</div>
            {Allvoyage.length > 0 &&


              <ToolkitProvider
                keyField="CodPer"
                data={Allvoyage}
                columns={columns}
                search
                exportCSV={{
                  onlyExportFiltered: true, exportAll: false,
                  fileName: "Liste_Commandes.csv",
                  blobType: 'text/plain;charset=utf-8 ',
                  noAutoBOM: false,
                  separator: ";"
                }}
              >
                {
                  props => (<div className='mx-2 mb-4 '>
                    <Row>
                      <Col md={4}>
                        <SearchBar {...props.searchProps} placeholder="Rechercher ..." className='border-primary mx-3 mb-3 w-500' />
                      </Col>

                      <Col md={2}>
                        <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter CSV </ExportCSVButton>
                      </Col>
                    </Row>
                    <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                      <Col>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          pagination={paginationFactory()}
                          filter={filterFactory()}
                          expandRow={expandRow}
                          {...props.baseProps}

                        />
                      </Col>
                    </Row>
                  </div>
                  )
                }

              </ToolkitProvider>
            }
            <Modal
              className='modal'
              dialogClassName='modal-container'
              aria-labelledby="contained-modal-title-vcenter"
              show={show}
              onHide={handleClose}
              size='lg'
            >
              <Modal.Header style={{ backgroundColor: '#DCDCDC' }}>
                <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'white' }}>

                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Devis cmd={cmd} />


              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

              </Modal.Footer>
            </Modal>


            <Modal
              className='modal'
              dialogClassName='modal-dialog modal-lg'
              // aria-labelledby="contained-modal-title-vcenter"
              show={showcmd}
              // onHide={handleClose2}
              size='xl'
            >
              <Modal.Header style={{ backgroundColor: '#DCDCDC' }}>
                <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'black' }}>
                  Détail Commande N° {cmd.Nf}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DetailCommande cmd={cmd}
                  onPress={handleClose2}
                />
                {/* <AddEmployer Emp={Emp} /> */}

              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

              </Modal.Footer>
            </Modal>
          </div>


        </div>
      </div>
    </div>
          }
          </>
  );
}


export default ListCommande;



